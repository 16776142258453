import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const kardiologia = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO title="Kardiologia"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący echo serca, RTG klatki piersiowej psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
    keywords="kardiolog zwierząt kalisz, lekarz weterynarii kalisz"
    />
    
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Kardiologia</BigTitlePages>  
         
         <FlexContent>
          <UslugiPages />
 <Flex>

 

    <BoxTextPages>

<TitleServices>Świadczymy usługi kardiologiczne w zakresie:</TitleServices>
<ListServices> Echo serca -pozwalające na zdiagnozowanie i odpowiednie leczenie wrodzonych i nabytych chorób serca i głównych naczyń u psów i kotów
</ListServices>
<ListServices> RTG klatki piersiowej
</ListServices>



    </BoxTextPages>

 <StaticImage
    src="../images/kardiologia.jpeg"
    alt="Kardiologia zwierząt"
    width={600}
    placeholder="blurred"
    className='staticimagepages'
    />
    
</Flex>
</FlexContent>
<Footer />
  </ThemeProvider>  
  </>
  )
}

export default kardiologia